import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import VacationTableItem from "@/entity/attendance/duty/VacationTableItem";
import API from "@/api/attendance/duty/vacation";
import { ElMessageBox } from "element-plus";

@Options({
    name: "app-attendance-duty-vacation",
})
export default class Vacation extends BaseTableMixins<VacationTableItem> implements Partial<BaseTableInterface<VacationTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new VacationTableItem();
        this.editModalItem = new VacationTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new VacationTableItem();
        this.editModalItem = new VacationTableItem();
        this.editModalItem.vacationStatus = 1;
        this.editModalTitle = "添加假期";
        this.editModalSubmitButtonName = "添加";
    }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改批次 (" + this.editModalItem.vacationName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.vacationId)) return "批次ID不能为空！";
        return this.addSubmitChecking();
    }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除假期",
            message: "假期名称: " + this.editModalItem.vacationName + "<br>请确认删除？",
        };
    }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.vacationId)) return "假期ID不能为空！";
        return null;
    }
    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "假期规则排序";
    }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "假期状态",
            message: "假期: " + this.editModalItem.vacationName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.vacationStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.vacationStatus = this.editModalItem.vacationStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.vacationId)) return "假期ID不能为空！";
        return null;
    }

    private btnDelVacation(item: VacationTableItem) {
        const vacationDdId = item.vacationDdId;
        ElMessageBox.confirm("是否要删除这条假期", "注意", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(() => {
                this.isLoading = true;
                API.delVacationOne({ vacationDdId })
                    .then((response) => {
                        console.log(response.data);
                        this.toast.success("删除成功");
                        this.getList(0);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            })
            .catch(() => {});
    }

    //添加修改功能
    protected async editModalMod(): Promise<boolean> {
        if (this.baseTableInterface.modSubmitChecking) {
            const modModalChecking = this.baseTableInterface.modSubmitChecking();
            if (!this.$tools.isEmpty(modModalChecking)) {
                this.toast.error(modModalChecking);
                return Promise.reject(false);
            }
        }
        await this.API.modVacationOne(this.editModalItem).then((res: any) => {
            if (this.baseTableInterface.modModalCallback) this.baseTableInterface.modModalCallback(res);
            this.toast.success(res.msg);
            this.getList(0);
        });
        return Promise.resolve(true);
    }

    //添加功能
    protected async editModalAdd(): Promise<boolean> {
        if (this.baseTableInterface.addSubmitChecking) {
            const addModalChecking = this.baseTableInterface.addSubmitChecking();
            if (!this.$tools.isEmpty(addModalChecking)) {
                this.toast.error(addModalChecking);
                return Promise.reject(false);
            }
        }
        await API.addVacationOne(this.editModalItem).then((res: any) => {
            if (this.baseTableInterface.addModalCallback) this.baseTableInterface.addModalCallback(res);
            this.toast.success(res.msg);
            this.getList(0);
        });
        return Promise.resolve(true);
    }

    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.vacationName)) return "假期规则不能为空！";
        return null;
    }
}
