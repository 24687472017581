import requests from "@/utils/axios";
import baseApi from "@/api/baseApi";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/attendance/duty/vacation",
    delVacationOne(query: any) {
        return requests.post(this.modulesUrl + "/delVacation", query);
    },
    addVacationOne(query: any) {
        return requests.post(this.modulesUrl + "/addVacation", query);
    },
    modVacationOne(query: any) {
        return requests.post(this.modulesUrl + "/modVacation", query);
    },
};
