export default class VacationTableItem {
    vacationId = 0;
    vacationStatus = 0;
    vacationName = "";
    vacationDdId = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
